// src/composables/useMqtt.ts

import mqtt from "mqtt";
import { MessageHandler } from "@/types/interfaces";
import { Host, Connection, JwtResponse  } from "../types/interfaces";

/**
 * ホスト一覧を取得
 */
export async function getHosts(): Promise<Host[]> {
  const res = await fetch("/api/v4/emqx/hosts");
  if (!res.ok) {
    throw new Error("Failed to fetch host");
  }
  const data = await res.json();
  return data.hosts;
}

/**
 * サーバーへ接続し、Connection オブジェクトを返す
 */
export async function connectToServer(selectedHost: Host): Promise<Connection> {
  const res = await fetch(`/api/v4/emqx/gen/jwt?host=${selectedHost.host}`);
  if (!res.ok) throw new Error("Failed to fetch JWT token");

  const data = await res.json();
  const jwtData: JwtResponse = data;

  const brokerUrl = `wss://${selectedHost.host}:8084/mqtt`;
  const options = {
    protocolId: "MQTT",
    username: jwtData.username,
    clientId: "vue_client_" + Math.random().toString(16).slice(2, 10),
    password: jwtData.token,
  };

  const mqttClient = mqtt.connect(brokerUrl, options);

  // 新しい接続情報を返す
  return {
    host: jwtData.host,
    token: jwtData.token,
    roles: jwtData.roles,
    connected: false,
    mqttClient,
    username: jwtData.username,
    receivedMessages: [],
    activeSubscriptions: new Set<string>(),
  };
}

/**
 * トークンを再取得し、connection を再接続する
 */
export async function refreshToken(connection: Connection) {
  const res = await fetch(`/api/v4/emqx/gen/jwt?host=${connection.host}`);
  if (!res.ok) throw new Error("Failed to refresh JWT token");

  const data = await res.json();
  const jwtData: JwtResponse = data;

  // トークンを更新
  connection.token = jwtData.token;
  connection.roles = jwtData.roles;

  // MQTTクライアントを再接続
  connection.mqttClient.end(true, () => {
    const brokerUrl = `wss://${connection.host}:8084/mqtt`;
    const options = {
      protocolId: "MQTT",
      username: connection.username,
      clientId: "vue_client_" + Math.random().toString(16).slice(2, 10),
      password: jwtData.token,
      reconnectPeriod: 5000,
    };
    connection.mqttClient = mqtt.connect(brokerUrl, options);
  });
}

/**
 * MQTT イベントリスナーの設定
 * - Vue コンポーネントのメソッドから呼び出す際に、
 *   「connection」と「Vue インスタンス（this 相当）」を渡す想定
 */
export function setupMqttListeners(
  connection: Connection,
  vueInstance: any, // this に相当するコンポーネントインスタンス
  messageHandlers: MessageHandler[] = []
) {
  connection.mqttClient.on("connect", () => {
    console.log(`Connected to ${connection.host}`);
    connection.connected = true;

    // 接続成功時にアクティブ接続として設定
    vueInstance.activeConnection = connection;

    // connections を再代入して Vue の反応性を保つ
    vueInstance.connections = [...vueInstance.connections];

    vueInstance.$emit("show-flash", {
      message: `${connection.host} に接続しました。`,
      type: "success",
    });

    // 自動購読は行わず、手動で購読する方式
    vueInstance.activeSubscriptions = new Set<string>();

    // デフォルトで subscribeTopics を呼びたい場合
    vueInstance.subscribeTopics();
  });

  connection.mqttClient.on("error", (err: any) => {
    console.error(`MQTT connection error on ${connection.host}:`, err);
    connection.connected = false;
    vueInstance.connections = [...vueInstance.connections];
  });

  connection.mqttClient.on("close", () => {
    console.log(`Connection closed for ${connection.host}`);
    connection.connected = false;
    vueInstance.connections = vueInstance.connections.filter(
      (conn: Connection) => conn.host !== connection.host
    );

    if (vueInstance.activeConnection?.host === connection.host) {
      const nextConnection = vueInstance.connections.find(
        (conn: Connection) => conn.connected
      );
      vueInstance.activeConnection = nextConnection || null;
      vueInstance.selectedTopic = "";
    }

    vueInstance.$emit("show-flash", {
      message: `${connection.host} から切断しました。`,
      type: "info",
    });
  });

  connection.mqttClient.on("offline", () => {
    console.log(`Client offline for ${connection.host}`);
    connection.connected = false;
    vueInstance.connections = [...vueInstance.connections];
  });

  connection.mqttClient.on("reconnect", async () => {
    console.log(`Reconnecting to ${connection.host}...`);
    await vueInstance.refreshToken(connection);
  });

  connection.mqttClient.on("message", (topic: string, payload: Buffer) => {
    try {
      const message = {
        topic,
        payload: payload.toString(),
        timestamp: new Date().toLocaleString(),
      };
      
      // メッセージハンドラーの実行
      const payloadObj = JSON.parse(payload.toString());
      messageHandlers.forEach( (handler :any)=> {
        if (topic.match(handler.topic)) {
          handler.handler(payloadObj);
        }
      });

      connection.receivedMessages.unshift(message);
      vueInstance.connections = [...vueInstance.connections];
    } catch (error) {
      console.error('Failed to process message:', error);
    }
  });
}

/**
 * 切断処理
 */
export function disconnectServer(
  connection: Connection,
  vueInstance: any
) {
  console.log(`Disconnecting from ${connection.host}`);
  connection.mqttClient.end(true, () => {
    connection.connected = false;
    vueInstance.connections = vueInstance.connections.filter(
      (conn: Connection) => conn.host !== connection.host
    );

    if (vueInstance.activeConnection?.host === connection.host) {
      const nextConnection = vueInstance.connections.find(
        (conn: Connection) => conn.connected
      );
      vueInstance.activeConnection = nextConnection || null;
      vueInstance.selectedTopic = "";
    }

    vueInstance.$emit("show-flash", {
      message: `${connection.host} から切断しました。`,
      type: "info",
    });
  });
}
