
import { defineComponent } from "vue";
import {
  getHosts,
  connectToServer,
  refreshToken,
  setupMqttListeners,
  disconnectServer,
} from "./composables/useMqtt"; // ← 切り出した関数をインポート

import Tagify from '@yaireo/tagify'
import '@yaireo/tagify/dist/tagify.css';


import {
  faFile,
  faChartBar,
  faMicrochip,
  faTablet,
  faSnowflake,
} from "@fortawesome/free-solid-svg-icons";
import { MessageHandler } from "@/types/interfaces";
import { Host, Role, Token, Connection } from "./types/interfaces";

export default defineComponent({
  name: "Command",
  data() {
    return {
      selectedCA: undefined,
      message: "",
      showViews: false,
      faFile,
      faMicrochip,
      faSnowflake,
      faTablet,
      faChartBar,
      mqttClient: null as any,
      tokens: [] as Token[],
      selectedServer: null as Token | null,
      hosts: [] as Host[],
      selectedTopic: "",
      selectedHost: null as Host | null,
      isConnecting: false,
      connections: [] as Connection[],
      activeConnection: null as Connection | null,
      showNewConnection: false,
      selectedPublishTopic: "",
      showSubscribeSelect: false,
      config : {},
      activeLogTab: "command",
      logs: [] as any[],
      continuationToken: "",
      selectedLogTopic: "",
      availableLogTopics: [] as string[],
      relayStates: [] as Array<{id: number, name: string, state: boolean}>,
      
      toTag: null as Tagify | null,
      ccTag: null as Tagify | null,
      bccTag: null as Tagify | null,

      messageHandlers: [
        {
          topic: "command/rex/dashboard.*",
          handler: (payload: any) => {
          
            if (payload.info && payload.info.state.module) {
              this.relayStates = payload.info.state.module;
            }
            if (payload.config ) {
              this.config = payload.config;
            }
            if (payload.info.config ) {
              this.config =  payload.info.config;
            }
            if (payload.state && payload.state.module) {
              this.relayStates = payload.state.module;
            }
          }
        }
      ] as MessageHandler[],
    };
  },

beforeMount(){

  
},
  mounted() {
    const initialized = this.initializeTagify();
     
    this.$emit("show-header");
    const parent = this.$parent?.$parent as any;
    this.showViews = parent?.isLoggedin ?? false;

  
    // getHosts 関数（外部化したもの）を呼び出し
    this.getHosts()
      .then((hosts) => {
        this.hosts = hosts.map((host) => host);
        if (this.hosts.length > 0) {
          this.selectedHost = this.hosts[0];
          this.connectToServer();
        }
      })
      .catch((error) => {
        console.error("Error getting hosts:", error);
        this.$emit("show-flash", {
          message: "Failed to get hosts",
          type: "danger",
        });
      });
  },
  computed: {
    availableHosts(): Host[] {
      return this.hosts.filter(
        (host) => !this.connections.some((conn) => conn.host === host.host)
      );
    },
    availableTopics(): Role[] {
      if (!this.activeConnection?.roles) return [];
      return this.activeConnection.roles.filter(
        (role:any) =>
          role.action === "publish" ||
          role.action === "all" ||
          role.action === "subscribe"
      );
    },
    publishableTopics(): Role[] {
      if (!this.activeConnection?.roles) return [];
      return this.activeConnection.roles.filter(
        (role:any) => role.action === "publish" || role.action === "all"
      );
    },
    subscribeableTopics(): Role[] {
      if (!this.activeConnection?.roles) return [];
      return this.activeConnection.roles.filter(
        (role:any) => role.action === "subscribe" || role.action === "all"
      );
    },
    availableSubscribeTopics(): Role[] {
      return this.subscribeableTopics.filter(
        (role:any) => ! (this as any).activeSubscriptions.has(role.topic)
      );
    },
  },
  methods: {
    // 外部化した getHosts を呼び出すだけ
    async getHosts(): Promise<Host[]> {
      return await getHosts();
    },

    onHostSelect() {
      if (this.mqttClient) {
        this.mqttClient.end();
        this.mqttClient = null;
      }
      this.selectedTopic = "";
      this.message = "";
    },

    async connectToServer() {
      if (!this.selectedHost) return;
      this.isConnecting = true;
      try {
        // 外部化した connectToServer を使って Connection を取得
        const connection = await connectToServer(this.selectedHost);

        // イベントリスナーを設定（第2引数に this を渡す）
        setupMqttListeners(connection, this, this.messageHandlers); // メッセージハンドラーを渡す

        // 接続リストに追加
        this.connections.push(connection);

        // 選択をリセット
        this.selectedHost = null;
        this.selectedTopic = "";
        this.showNewConnection = false;
      } catch (error) {
        console.error("Connection error:", error);
        this.$emit("show-flash", {
          message: "接続に失敗しました。",
          type: "danger",
        });
      } finally {
        this.isConnecting = false;
      }
    },

    // 外部化した refreshToken を呼び出す
    async refreshToken(connection: Connection) {
      try {
        await refreshToken(connection);
        // リスナーを再設定
        setupMqttListeners(connection, this);
      } catch (error) {
        console.error("Token refresh error:", error);
        this.$emit("show-flash", {
          message: "トークンの更新に失敗しました",
          type: "danger",
        });
      }
    },

    // 外部化した関数を呼び出し
    disconnectServer(connection: Connection) {
      disconnectServer(connection, this);
    },

    downloadCACertificate() {
      
      if (!this.selectedHost || !this.selectedHost.ca) return;
      const certContent = this.selectedHost.ca;
      const blob = new Blob([certContent], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${this.selectedHost.host}-ca.pem`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },

    sendUpdateSettingMessage(){

      this.relayStates=[];
      this.message = JSON.stringify(this.config);
      this.sendMessage();   
    },
    sendGetStateMessage() {
      this.relayStates=[];
      this.message = '{"command":"getstate"}';
      this.sendMessage();
    },
    sendGetConfigMessage() {
      this.config={};
      this.message = '{"command":"getcofig"}';
      this.sendMessage();
    },

    sendGetInfoMessage() {
      this.relayStates=[];
      this.config={};

      this.message = '{"command":"getinfo"}';
      this.sendMessage();      

    },

    sendTurnOnMessage(relay:any){

      this.message = '{"command": [{"id": '+relay.id+', "switch": '+(relay.state)+'}]}';    
      this.sendMessage();  
      this.message="";    
    },
    sendMessage() {

      if (!this.activeConnection || !this.selectedPublishTopic || !this.message.trim()) {
        this.$emit("show-flash", {
          message: "接続、トピック、メッセージを確認してください。",
          type: "warning",
        });
        return;
      }
      const role = this.publishableTopics.find(
        (r) => r.topic === this.selectedPublishTopic
      );
      if (!role) {
        this.$emit("show-flash", {
          message: "このトピックへの publish 権限がありません。",
          type: "danger",
        });
        return;
      }
      if (this.activeConnection.connected) {
        this.activeConnection.mqttClient.publish(
          this.selectedPublishTopic,
          this.message,
          { qos: role.qos[0] },
          (error: any) => {
            if (error) {
              this.$emit("show-flash", {
                message: "メッセージの送信に失敗しました。",
                type: "danger",
              });
            } else {
              this.$emit("show-flash", {
                message: "メッセージを送信しました。",
                type: "success",
              });
              this.message = "";
            }
          }
        );
      }
    },

    // 購読開始
    subscribeTopics() {
      if (!this.activeConnection) return;
      for (let i = 0; i < this.subscribeableTopics.length; i++) {
        let role = this.subscribeableTopics[i];
        if (role) {
          (this as any).activeConnection.mqttClient.subscribe(
            role.topic,
            { qos: role.qos[0] },
            (err: any) => {
              if (!err) {
                (this as any).activeConnection.activeSubscriptions.add(role.topic);
                this.$emit("show-flash", {
                  message: `${role.topic} の購読を開始しました。`,
                  type: "success",
                });
                this.showSubscribeSelect = false;
                this.connections = [...this.connections];
              } else {
                this.$emit("show-flash", {
                  message: "購読開始に失敗しました。",
                  type: "danger",
                });
              }
            }
          );
        }
      }
    },

    // 購読解除
    unsubscribeTopic(topic: string) {
      if (!this.activeConnection) return;
      this.activeConnection.mqttClient.unsubscribe(topic, (err: any) => {
        if (!err) {
          (this as any).activeConnection.activeSubscriptions.delete(topic);
          this.$emit("show-flash", {
            message: `${topic} の購読を解除しました。`,
            type: "info",
          });
          this.connections = [...this.connections];
        }
      });
    },

    async switchLogTab(tab: "command" | "event") {
      this.activeLogTab = tab;
      this.logs = [];
      this.continuationToken = "";
      await this.fetchLogs();
    },

    async fetchLogs() {
      try {
        if (!this.activeConnection) {
          console.warn("No active connection");
          return;
        }

        let pkey;
        const params = new URLSearchParams({
          tz: "Asia/Tokyo",
        });

        if (this.activeLogTab === "command") {
          pkey = `${this.activeConnection.username}:message.publish`;
          if (this.selectedLogTopic) {
            params.append("filter", `topic eq '${this.selectedLogTopic}'`);
          }
        } else {
          pkey = `${this.activeConnection.username}:message.delivered`;
        }
        params.append("pkey", pkey);

        if (this.continuationToken) {
          params.append("continuationToken", this.continuationToken);
        }

        const endpoint =
          this.activeLogTab === "command"
            ? "/api/v4/command/log/command"
            : "/api/v4/command/log/event";

        const response = await fetch(`${endpoint}?${params}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // 初回のみトピック一覧を更新
        if (
          this.activeLogTab === "command" &&
          !this.continuationToken &&
          this.activeConnection
        ) {
          const topics = this.activeConnection.roles
            .map((role : any) => role.topic)
            .filter(Boolean);
          (this as any).availableLogTopics = [...new Set(topics)].sort();
        }

        if (this.continuationToken) {
          this.logs = [...this.logs, ...data.data];
        } else {
          this.logs = data.data;
        }

        this.continuationToken = data.continuationToken || "";
      } catch (error) {
        console.error("Error fetching logs:", error);
        this.$emit("show-flash", {
          message: "ログの取得に失敗しました",
          type: "danger",
        });
      }
    },

    async onLogTopicChange() {
      this.logs = [];
      this.continuationToken = "";
      await this.fetchLogs();
    },

    async loadMoreLogs() {
      if (this.continuationToken) {
        await this.fetchLogs();
      }
    },

    formatTimestamp(timestamp: number): string {
      return new Date(timestamp).toLocaleString("ja-JP", {
        timeZone: "Asia/Tokyo",
      });
    },
    initializeTagify() {
      // DOMが存在することを確認
      const toElement = this.$refs.to as HTMLElement;
      const ccElement = this.$refs.cc as HTMLElement;
      const bccElement = this.$refs.bcc as HTMLElement;

      if (!toElement || !ccElement || !bccElement) {
        console.warn('Required elements not found');
        return false;
      }
     
      try {
        // 既存のTagifyインスタンスを破棄
        if (this.toTag) this.toTag.destroy();
        if (this.ccTag) this.ccTag.destroy();
        if (this.bccTag) this.bccTag.destroy();
     
        // 新しいインスタンスを作成
        this.toTag = new Tagify( ( toElement as any), {
          maxTags: 20,
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          dropdown: { enabled: 0 }
        });
        console.log(5);
        this.ccTag = new Tagify((ccElement as any), {
          maxTags: 20,
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          dropdown: { enabled: 0 }
        });

        this.bccTag = new Tagify((bccElement as any), {
          maxTags: 20,
          pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          dropdown: { enabled: 0 }
        });

        return true;
      } catch (error) {
        console.log("er");
        console.log(error);
        console.error('Tagify initialization error:', error);
        return false;
      }
    },

    updateTagifyValues() {
      
      if (! ( this.config as any).mail) return;

      try {
        if ( ( this.config as any).mail?.to && this.toTag) {
          this.toTag.removeAllTags();
          this.toTag.addTags( (this.config as any).mail?.to.split(','));
        }
        if ( ( this.config as any).mail?.cc && this.ccTag) {
          this.ccTag.removeAllTags();
          this.ccTag.addTags((this.config as any).mail?.cc.split(','));
        }
        if ( ( this.config as any).mail?.bcc && this.bccTag) {
          this.bccTag.removeAllTags();
          this.bccTag.addTags((this.config as any).mail?.bcc.split(','));
        }
      } catch (error) {
        console.error('Failed to update tags:', error);
      }
    }
  },
  watch: {
    activeConnection: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchLogs();
        }
      },

    },
    config: {
      deep: true,
      handler(newVal) {
        console.log(newVal);
        if (newVal?.mail) {
          console.log(11);
          this.$nextTick(() => {
            // DOM更新を待ってから初期化
            setTimeout(() => {

             
                (this as any).updateTagifyValues();
            }, 100);
          });
        }
      }
    }    
  },
});
